$(document).on('turbolinks:load', function(){
    $('table.sortable').DataTable({
        "paging": false,
        "searching": false,
        "info": false,
        "autoWidth": false
    });

    $('table.sortable-decks').DataTable({
        "paging": false,
        "searching": false,
        "info": false,
        "autoWidth": false,
        "order": [[3, "desc"]]
    });
});
